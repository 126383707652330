import(/* webpackMode: "eager" */ "/home/crazyjane/_work/2/s/node_modules/.pnpm/next-client-cookies@1.1.1_next@13.5.6_react@18.3.1/node_modules/next-client-cookies/dist/provider.js");
import(/* webpackMode: "eager" */ "/home/crazyjane/_work/2/s/node_modules/.pnpm/next@13.5.6_@babel+core@7.24.3_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/crazyjane/_work/2/s/packages/ui-library/dist/reset.css");
import(/* webpackMode: "eager" */ "/home/crazyjane/_work/2/s/packages/ui-library/dist/index.css");
import(/* webpackMode: "eager" */ "/home/crazyjane/_work/2/s/node_modules/.pnpm/@grupa-pracuj+header-library-sip@0.0.1300_@floating-ui+react@0.26.6_@grupa-pracuj+analytics-p_dif2hsw5mst3gcdvxp23p4e5ma/node_modules/@grupa-pracuj/header-library-sip/dist/esm/styles.css");
import(/* webpackMode: "eager" */ "/home/crazyjane/_work/2/s/node_modules/.pnpm/@grupa-pracuj+ui-library-sip@0.0.1292_react-dom@18.3.1_react@18.3.1_typescript@5.3.3/node_modules/@grupa-pracuj/ui-library-sip/dist/esm/styles.css");
import(/* webpackMode: "eager" */ "/home/crazyjane/_work/2/s/pages/auth-page/src/analytics/AnalyticsContext/AnalyticsContext.tsx");
import(/* webpackMode: "eager" */ "/home/crazyjane/_work/2/s/pages/auth-page/src/app/components/AppHeader/AppHeader.tsx");
import(/* webpackMode: "eager" */ "/home/crazyjane/_work/2/s/pages/auth-page/src/app/components/InitGtm/InitGtm.tsx");
import(/* webpackMode: "eager" */ "/home/crazyjane/_work/2/s/pages/auth-page/src/app/components/InitTracker/InitTracker.tsx");
import(/* webpackMode: "eager" */ "/home/crazyjane/_work/2/s/pages/auth-page/src/assets/styles/global.css");
import(/* webpackMode: "eager" */ "/home/crazyjane/_work/2/s/pages/auth-page/src/context/BusinessVariablesContext.tsx");
import(/* webpackMode: "eager" */ "/home/crazyjane/_work/2/s/pages/auth-page/src/context/EnvironmentContext.tsx")